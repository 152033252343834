const ReviewStars = () => {
  return (
    <svg
      width="58"
      height="10"
      viewBox="0 0 58 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.9987 1.03711L5.98232 4.06438H9.16537L6.59022 5.93533L7.57384 8.96259L4.9987 7.09164L2.42356 8.96259L3.40717 5.93533L0.832031 4.06438H4.01508L4.9987 1.03711Z"
        fill="#0E0E0E"
      />
      <path
        d="M16.9987 1.03711L17.9823 4.06438H21.1654L18.5902 5.93533L19.5738 8.96259L16.9987 7.09164L14.4236 8.96259L15.4072 5.93533L12.832 4.06438H16.0151L16.9987 1.03711Z"
        fill="#0E0E0E"
      />
      <path
        d="M28.9987 1.03711L29.9823 4.06438H33.1654L30.5902 5.93533L31.5738 8.96259L28.9987 7.09164L26.4236 8.96259L27.4072 5.93533L24.832 4.06438H28.0151L28.9987 1.03711Z"
        fill="#0E0E0E"
      />
      <path
        d="M40.9987 1.03711L41.9823 4.06438H45.1654L42.5902 5.93533L43.5738 8.96259L40.9987 7.09164L38.4236 8.96259L39.4072 5.93533L36.832 4.06438H40.0151L40.9987 1.03711Z"
        fill="#0E0E0E"
      />
      <path
        d="M52.9997 1.0407V7.09197L52.9988 7.09136L50.4242 8.96192L51.4076 5.93528L48.833 4.06472H52.0154L52.9988 1.03809L52.9997 1.0407Z"
        fill="#0E0E0E"
      />
      <path
        d="M53.0003 1.0407V7.09197L53.0012 7.09136L55.5758 8.96192L54.5924 5.93528L57.167 4.06472H53.9846L53.0012 1.03809L53.0003 1.0407Z"
        fill="#0E0E0E"
        fillOpacity="0.28"
      />
    </svg>
  );
};

export default ReviewStars;
