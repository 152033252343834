export const VippsGrey = () => {
  return (
    <svg
      width="34"
      height="9"
      viewBox="0 0 34 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6486_12098)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.38939 0.956039L4.03214 4.83712L2.6749 0.956039H1.08594L3.44732 6.7182H4.62801L6.98939 0.956039H5.40042H5.38939ZM12.3632 2.59928C12.3632 3.05334 11.999 3.37766 11.5577 3.37766C11.1163 3.37766 10.7521 3.05334 10.7521 2.59928C10.7521 2.14523 11.1163 1.8209 11.5577 1.8209C11.999 1.8209 12.3632 2.14523 12.3632 2.59928ZM10.5204 5.77766C11.5135 5.77766 12.0763 5.3128 12.617 4.63171C12.9039 4.26415 13.279 4.18847 13.5439 4.39388C13.8197 4.59928 13.8308 4.97766 13.5439 5.35604C12.7715 6.35063 11.7894 6.95604 10.5204 6.95604C9.25145 6.95604 7.96042 6.2209 7.11076 4.94523C6.87904 4.6209 6.93421 4.23171 7.18801 4.05874C7.46387 3.86415 7.85008 3.95063 8.09283 4.30739C8.71076 5.18307 9.50525 5.78847 10.5204 5.78847M16.159 3.8209C16.159 4.99928 16.8542 5.6155 17.6377 5.6155C18.4211 5.6155 19.1384 5.03171 19.1384 3.8209C19.1384 2.61009 18.377 2.04793 17.6487 2.04793C16.9204 2.04793 16.159 2.59928 16.159 3.8209ZM16.159 0.96685V1.75604C16.5563 1.2155 17.1852 0.804688 18.1011 0.804688C19.017 0.804688 20.5949 1.74523 20.5949 3.74523C20.5949 5.74523 19.3039 6.85874 17.9797 6.85874C16.6556 6.85874 16.6446 6.58847 16.159 5.95063V8.70739H14.7135V0.96685H16.159ZM23.1108 3.8209C23.1108 4.99928 23.8059 5.6155 24.5894 5.6155C25.3728 5.6155 26.0901 5.03171 26.0901 3.8209C26.0901 2.61009 25.3287 2.04793 24.6004 2.04793C23.8721 2.04793 23.1108 2.59928 23.1108 3.8209ZM23.1108 0.96685V1.75604C23.508 1.2155 24.137 0.804688 25.0528 0.804688C25.9687 0.804688 27.5466 1.74523 27.5466 3.74523C27.5466 5.74523 26.2556 6.85874 24.9315 6.85874C23.6073 6.85874 23.5963 6.58847 23.1108 5.95063V8.70739H21.6652V0.96685H23.1108Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.6013 0.80378C31.793 0.80378 32.6648 1.34432 33.0289 2.69567L31.7268 2.89027C31.7048 2.19837 31.2634 1.99297 30.6344 1.99297C30.0055 1.99297 29.7848 2.19837 29.7848 2.53351C29.7848 2.86864 29.9723 3.06324 30.5241 3.16054L31.5172 3.34432C32.4882 3.5281 33.0179 4.14432 33.0179 4.98756C33.0179 6.23081 31.8592 6.85783 30.7668 6.85783C29.6744 6.85783 28.3392 6.27405 28.1406 4.86864L29.4427 4.67405C29.5199 5.39837 29.9723 5.65783 30.7117 5.65783C31.451 5.65783 31.6606 5.46324 31.6606 5.10648C31.6606 4.74973 31.4841 4.56594 30.8661 4.45783L29.9613 4.29567C29.0013 4.1227 28.4165 3.46324 28.4165 2.63081C28.4165 1.33351 29.6303 0.792969 30.5903 0.792969"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6486_12098">
          <rect
            width="32"
            height="8"
            fill="white"
            transform="translate(1.08594 0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const Vipps = () => {
  return (
    <svg
      width="36"
      height="16"
      viewBox="0 0 36 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6483_72681)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.8468 3.63848L3.32226 8.09631L1.78556 3.63861H-0.00488281L2.65204 10.2509H3.98032L6.63724 3.63848H4.8468ZM12.6907 5.52949C12.6907 6.05658 12.2748 6.42329 11.7896 6.42329C11.3045 6.42329 10.8887 6.05658 10.8887 5.52949C10.8887 5.00227 11.3045 4.63569 11.7896 4.63569C12.2748 4.63569 12.6907 5.00227 12.6907 5.52949ZM10.6111 9.18513C11.7317 9.18513 12.3671 8.64649 12.9679 7.8674C13.2912 7.44346 13.7186 7.3629 14.0074 7.5923C14.3193 7.83301 14.3308 8.26849 14.0074 8.69242C13.1412 9.83847 12.0322 10.526 10.6111 10.526C9.06327 10.526 7.73487 9.67809 6.77615 8.22268C6.51062 7.84443 6.57976 7.40895 6.86851 7.20263C7.18047 6.98489 7.60788 7.07651 7.8852 7.48915C8.57826 8.49753 9.46783 9.18513 10.6111 9.18513ZM16.9537 6.92754C16.9537 8.27991 17.7393 8.99048 18.6173 8.99048C19.4489 8.99048 20.3038 8.32572 20.3038 6.92754C20.3038 5.55221 19.4489 4.88769 18.6287 4.88769C17.7393 4.88769 16.9537 5.51794 16.9537 6.92754ZM16.9537 3.6499V4.55549C17.4043 3.93654 18.1088 3.4668 19.1369 3.4668C20.4308 3.4668 21.9441 4.54383 21.9441 6.84747C21.9441 9.25403 20.4887 10.423 18.9983 10.423C18.2013 10.423 17.4967 10.1134 16.9537 9.3799V12.543H15.325V3.6499H16.9537ZM24.7747 6.92754C24.7747 8.27991 25.5601 8.99048 26.4381 8.99048C27.2697 8.99048 28.1245 8.32572 28.1245 6.92754C28.1245 5.55221 27.2697 4.88769 26.4495 4.88769C25.5601 4.88769 24.7745 5.51794 24.7745 6.92754H24.7747ZM24.7747 3.6499V4.55549C25.2252 3.93654 25.9296 3.4668 26.9578 3.4668C28.2516 3.4668 29.7649 4.54383 29.7649 6.84747C29.7649 9.25403 28.3094 10.423 26.8191 10.423C26.0221 10.423 25.3175 10.1134 24.7745 9.3799V12.543H23.1458V3.6499H24.7745H24.7747Z"
          fill="#FF5B24"
        />
        <mask
          id="mask0_6483_72681"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="30"
          y="3"
          width="6"
          height="8"
        >
          <path
            d="M35.934 3.4668V10.423H30.4355V3.4668H35.9341H35.934Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_6483_72681)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.2077 3.4668C34.5479 3.4668 35.5297 4.0855 35.9341 5.64419L34.4669 5.87335C34.4438 5.08259 33.9472 4.84213 33.2424 4.84213C32.6879 4.84213 32.2837 5.08259 32.2837 5.46084C32.2837 5.75877 32.4915 6.06825 33.1154 6.18283L34.2358 6.3889C35.3332 6.59547 35.9224 7.30579 35.9224 8.28003C35.9224 9.71247 34.6171 10.4232 33.3925 10.4232C32.0987 10.4232 30.6664 9.75828 30.4355 8.14249L31.9025 7.9132C31.9834 8.7499 32.5033 9.04783 33.3349 9.04783C33.9702 9.04783 34.3977 8.81867 34.3977 8.41758C34.3977 8.06229 34.2013 7.79862 33.5081 7.67262L32.4915 7.48939C31.4174 7.2945 30.7591 6.53799 30.7591 5.57542C30.7591 4.08551 32.1218 3.4668 33.2077 3.4668Z"
            fill="#FF5B24"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_6483_72681">
          <rect
            width="36"
            height="9.18628"
            fill="white"
            transform="translate(0 3.40723)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Vipps;
