export const GoogleGreyscale = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6404_16441)">
        <mask
          id="mask0_6404_16441"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="12"
          height="12"
        >
          <path
            d="M11.125 5H6V7.125H8.95C8.675 8.475 7.525 9.25 6 9.25C4.2 9.25 2.75 7.8 2.75 6C2.75 4.2 4.2 2.75 6 2.75C6.775 2.75 7.475 3.025 8.025 3.475L9.625 1.875C8.65 1.025 7.4 0.5 6 0.5C2.95 0.5 0.5 2.95 0.5 6C0.5 9.05 2.95 11.5 6 11.5C8.75 11.5 11.25 9.5 11.25 6C11.25 5.675 11.2 5.325 11.125 5Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_6404_16441)">
          <path d="M0 9.25V2.75L4.25 6L0 9.25Z" fill="#BBBBBB" />
        </g>
        <mask
          id="mask1_6404_16441"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="12"
          height="12"
        >
          <path
            d="M11.125 5H6V7.125H8.95C8.675 8.475 7.525 9.25 6 9.25C4.2 9.25 2.75 7.8 2.75 6C2.75 4.2 4.2 2.75 6 2.75C6.775 2.75 7.475 3.025 8.025 3.475L9.625 1.875C8.65 1.025 7.4 0.5 6 0.5C2.95 0.5 0.5 2.95 0.5 6C0.5 9.05 2.95 11.5 6 11.5C8.75 11.5 11.25 9.5 11.25 6C11.25 5.675 11.2 5.325 11.125 5Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_6404_16441)">
          <path d="M0 2.75L4.25 6L6 4.475L12 3.5V0H0V2.75Z" fill="#747474" />
        </g>
        <mask
          id="mask2_6404_16441"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="12"
          height="12"
        >
          <path
            d="M11.125 5H6V7.125H8.95C8.675 8.475 7.525 9.25 6 9.25C4.2 9.25 2.75 7.8 2.75 6C2.75 4.2 4.2 2.75 6 2.75C6.775 2.75 7.475 3.025 8.025 3.475L9.625 1.875C8.65 1.025 7.4 0.5 6 0.5C2.95 0.5 0.5 2.95 0.5 6C0.5 9.05 2.95 11.5 6 11.5C8.75 11.5 11.25 9.5 11.25 6C11.25 5.675 11.2 5.325 11.125 5Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask2_6404_16441)">
          <path d="M0 9.25L7.5 3.5L9.475 3.75L12 0V12H0V9.25Z" fill="#999999" />
        </g>
        <mask
          id="mask3_6404_16441"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="12"
          height="12"
        >
          <path
            d="M11.125 5H6V7.125H8.95C8.675 8.475 7.525 9.25 6 9.25C4.2 9.25 2.75 7.8 2.75 6C2.75 4.2 4.2 2.75 6 2.75C6.775 2.75 7.475 3.025 8.025 3.475L9.625 1.875C8.65 1.025 7.4 0.5 6 0.5C2.95 0.5 0.5 2.95 0.5 6C0.5 9.05 2.95 11.5 6 11.5C8.75 11.5 11.25 9.5 11.25 6C11.25 5.675 11.2 5.325 11.125 5Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask3_6404_16441)">
          <path d="M12 12L4.25 6L3.25 5.25L12 2.75V12Z" fill="#7D7D7D" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_6404_16441">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const Google = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g style={{ mixBlendMode: "screen" }} clipPath="url(#clip0_5857_114516)">
        <mask
          id="mask0_5857_114516"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="12"
          height="12"
        >
          <path
            d="M11.125 5H6V7.125H8.95C8.675 8.475 7.525 9.25 6 9.25C4.2 9.25 2.75 7.8 2.75 6C2.75 4.2 4.2 2.75 6 2.75C6.775 2.75 7.475 3.025 8.025 3.475L9.625 1.875C8.65 1.025 7.4 0.5 6 0.5C2.95 0.5 0.5 2.95 0.5 6C0.5 9.05 2.95 11.5 6 11.5C8.75 11.5 11.25 9.5 11.25 6C11.25 5.675 11.2 5.325 11.125 5Z"
            fill="white"
            style={{ fill: "var(--svg-color)" }}
          />
        </mask>
        <g mask="url(#mask0_5857_114516)">
          <path d="M0 9.25V2.75L4.25 6L0 9.25Z" fill="#555555" />
        </g>
        <mask
          id="mask1_5857_114516"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="12"
          height="12"
        >
          <path
            d="M11.125 5H6V7.125H8.95C8.675 8.475 7.525 9.25 6 9.25C4.2 9.25 2.75 7.8 2.75 6C2.75 4.2 4.2 2.75 6 2.75C6.775 2.75 7.475 3.025 8.025 3.475L9.625 1.875C8.65 1.025 7.4 0.5 6 0.5C2.95 0.5 0.5 2.95 0.5 6C0.5 9.05 2.95 11.5 6 11.5C8.75 11.5 11.25 9.5 11.25 6C11.25 5.675 11.2 5.325 11.125 5Z"
            fill="white"
            style={{ fill: "var(--svg-color)" }}
          />
        </mask>
        <g mask="url(#mask1_5857_114516)">
          <path d="M0 2.75L4.25 6L6 4.475L12 3.5V0H0V2.75Z" fill="#9C9C9C" />
        </g>
        <mask
          id="mask2_5857_114516"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="12"
          height="12"
        >
          <path
            d="M11.125 5H6V7.125H8.95C8.675 8.475 7.525 9.25 6 9.25C4.2 9.25 2.75 7.8 2.75 6C2.75 4.2 4.2 2.75 6 2.75C6.775 2.75 7.475 3.025 8.025 3.475L9.625 1.875C8.65 1.025 7.4 0.5 6 0.5C2.95 0.5 0.5 2.95 0.5 6C0.5 9.05 2.95 11.5 6 11.5C8.75 11.5 11.25 9.5 11.25 6C11.25 5.675 11.2 5.325 11.125 5Z"
            fill="white"
            style={{ fill: "var(--svg-color)" }}
          />
        </mask>
        <g mask="url(#mask2_5857_114516)">
          <path d="M0 9.25L7.5 3.5L9.475 3.75L12 0V12H0V9.25Z" fill="#777777" />
        </g>
        <mask
          id="mask3_5857_114516"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="12"
          height="12"
        >
          <path
            d="M11.125 5H6V7.125H8.95C8.675 8.475 7.525 9.25 6 9.25C4.2 9.25 2.75 7.8 2.75 6C2.75 4.2 4.2 2.75 6 2.75C6.775 2.75 7.475 3.025 8.025 3.475L9.625 1.875C8.65 1.025 7.4 0.5 6 0.5C2.95 0.5 0.5 2.95 0.5 6C0.5 9.05 2.95 11.5 6 11.5C8.75 11.5 11.25 9.5 11.25 6C11.25 5.675 11.2 5.325 11.125 5Z"
            fill="white"
            style={{ fill: "var(--svg-color)" }}
          />
        </mask>
        <g mask="url(#mask3_5857_114516)">
          <path d="M12 12L4.25 6L3.25 5.25L12 2.75V12Z" fill="#939393" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_5857_114516">
          <rect
            width="12"
            height="12"
            fill="white"
            style={{ fill: "var(--svg-color)" }}
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Google;
