"use client";
import { VoucherHook } from "@/types/centra";
import { useTranslations } from "next-intl";
import UI from "./UI";

const Vouchers = ({ voucher }: { voucher: VoucherHook }) => {
  const t = useTranslations();
  return (
    <UI
      voucher={voucher}
      addVoucherLabel={t("vouchers.addReferenceCode")}
      applyLabel={t("vouchers.applyVoucher")}
      referenceCodeLabel={t("vouchers.referenceCode")}
    />
  );
};

export default Vouchers;
