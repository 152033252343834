import type { VoucherHook } from "@/types/centra";

import { Button } from "@/components/atoms";
import Close from "@/icons/Close";
import Minus from "@/icons/Minus";
import Plus from "@/icons/Plus";
import Input from "../Input";
import styles from "./vouchers.module.css";

const UI = (props: {
  voucher: VoucherHook;
  addVoucherLabel: string;
  applyLabel: string;
  referenceCodeLabel: string;
}) => {
  const { voucher, referenceCodeLabel, addVoucherLabel, applyLabel } = props;
  const {
    code,
    add,
    remove,
    activateInput,
    deactivateInput,
    showInput,
    showRemove,
    error,
    updateCode,
    isValid,
  } = voucher;

  if (showInput)
    return (
      <div className={styles.wrap}>
        <Button
          color="simple"
          className={styles["add-voucher"]}
          onClick={deactivateInput}
        >
          <span>
            <span>{addVoucherLabel}</span>
            <Minus />
          </span>
        </Button>
        <form className={styles.form} onSubmit={add}>
          <Input
            placeholder="Code"
            value={code}
            onChange={updateCode}
            isValid={isValid}
          />
          <span className={styles.buttons}>
            <Button>{applyLabel}</Button>
          </span>
        </form>

        {error && <p className={styles.error}>{error}</p>}
      </div>
    );
  if (showRemove)
    return (
      <div className={styles.container}>
        <div className={styles.activeVoucher}>
          <span className={styles.subContainer}>
            <p>
              {referenceCodeLabel} {code}
            </p>
            <Button
              onClick={remove}
              color="toggle"
              className={styles.toggle}
              label="close"
            >
              <Close />
            </Button>
          </span>
        </div>
      </div>
    );
  return (
    <div className={styles.wrap}>
      <Button
        color="simple"
        className={styles["add-voucher"]}
        onClick={activateInput}
      >
        <span>
          <span>{addVoucherLabel}</span>
          <Plus />
        </span>
      </Button>
    </div>
  );
};

export default UI;
