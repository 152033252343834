import { GoogleGreyscale } from "@/icons/Google";
import Amex from "@/icons/paymentProviders/Amex";
import Klarna from "@/icons/paymentProviders/Klarna";
import MasterCard from "@/icons/paymentProviders/Mastercard";
import Vipps from "@/icons/paymentProviders/Vipps";
import Visa from "@/icons/paymentProviders/Visa";
import ReviewStars from "@/icons/ReviewStars";
import Link from "next/link";
import styles from "./payment-providers.module.css";

export const GoogleRating = () => {
  const GOOGLE_RATING = 4.7;
  const GOOGLE_URL = "https://g.co/kgs/rXMbJWd";
  return (
    <Link href={GOOGLE_URL} className={styles.link} target="_blank">
      <div className={styles.googleRating}>
        <GoogleGreyscale />
        {GOOGLE_RATING}
        <ReviewStars />
      </div>
    </Link>
  );
};

const PaymentProviders = () => {
  return (
    <div className={styles.wrapper}>
      <MasterCard />
      <Visa />
      <Amex />
      <Klarna />
      <Vipps />
    </div>
  );
};

export default PaymentProviders;
