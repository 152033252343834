export const VisaGrey = () => {
  return (
    <svg
      width="32"
      height="10"
      viewBox="0 0 32 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6486_12075)">
        <path
          d="M12.226 0.455766L8.40089 9.5823H5.90528L4.02293 2.2989C3.9086 1.85023 3.80933 1.68598 3.46178 1.49701C2.89436 1.18909 1.95722 0.900343 1.13281 0.721021L1.18881 0.455766H5.20611C5.71817 0.455766 6.17845 0.796649 6.2947 1.38638L7.28885 6.66749L9.74589 0.455766H12.226ZM22.0045 6.6025C22.0146 4.19381 18.6738 4.06106 18.6967 2.98507C18.7039 2.65761 19.0157 2.30947 19.6981 2.2205C20.0364 2.17618 20.9685 2.14237 22.0257 2.62894L22.4403 0.69367C21.8723 0.487539 21.1414 0.289062 20.2319 0.289062C17.8984 0.289062 16.2562 1.52964 16.2423 3.30592C16.2272 4.61981 17.4145 5.35295 18.3089 5.78952C19.2291 6.23667 19.5378 6.52391 19.5344 6.92389C19.5278 7.53615 18.8004 7.80642 18.1207 7.81699C16.9341 7.83521 16.2455 7.49586 15.6965 7.24065L15.2686 9.24006C15.8202 9.49315 16.8384 9.71395 17.8941 9.72497C20.3745 9.72497 21.9969 8.49985 22.0045 6.6025ZM28.1667 9.5823H30.3502L28.4442 0.455766H26.4288C25.9756 0.455766 25.5935 0.71956 25.4241 1.12536L21.8813 9.5823H24.3603L24.8526 8.21901H27.8816L28.1667 9.5823ZM25.5324 6.34848L26.775 2.92179L27.4902 6.34848H25.5324ZM15.5994 0.455766L13.6471 9.5823H11.2863L13.2393 0.455766H15.5994Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6486_12075">
          <rect
            width="29.2326"
            height="9.45"
            fill="white"
            transform="translate(1.125 0.275391)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
const Visa = () => {
  return (
    <svg
      width="28"
      height="16"
      viewBox="0 0 28 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.631 3.6402L6.96524 12.3848H4.57362L2.76969 5.40619C2.66013 4.97631 2.56499 4.81893 2.23192 4.63787C1.68815 4.34284 0.790053 4.06617 0 3.89435L0.0536674 3.6402H3.90358C4.3943 3.6402 4.83541 3.96681 4.94681 4.53186L5.89953 9.59197L8.2542 3.6402H10.631ZM20.0021 9.52969C20.0117 7.2218 16.8101 7.09461 16.8321 6.06365C16.8389 5.74989 17.1378 5.41633 17.7918 5.33107C18.116 5.28861 19.0092 5.25621 20.0223 5.72243L20.4197 3.86814C19.8754 3.67064 19.1749 3.48047 18.3033 3.48047C16.067 3.48047 14.4932 4.66913 14.4799 6.37108C14.4655 7.62998 15.6033 8.33244 16.4604 8.75074C17.3423 9.17918 17.6381 9.45439 17.6348 9.83763C17.6285 10.4243 16.9315 10.6832 16.28 10.6934C15.1429 10.7108 14.4829 10.3857 13.9568 10.1411L13.5468 12.0569C14.0754 12.2994 15.0512 12.5109 16.0629 12.5215C18.4399 12.5215 19.9947 11.3476 20.0021 9.52969ZM25.9074 12.3848H28L26.1734 3.6402H24.242C23.8077 3.6402 23.4414 3.89295 23.2791 4.28177L19.884 12.3848H22.2597L22.7315 11.0786H25.6343L25.9074 12.3848ZM23.383 9.28631L24.5738 6.00302L25.2592 9.28631H23.383ZM13.8638 3.6402L11.9929 12.3848H9.73044L11.602 3.6402H13.8638Z"
        fill="#1434CB"
      />
    </svg>
  );
};

export default Visa;
