import {
  OwnersClubItemStoryblok,
  OwnersClubStoryblok,
} from "@/types/storyblok-blok-types";
import Image from "next/image";
import styles from "./index.module.css";

interface IFeatures {
  ownersClubBlok?: OwnersClubStoryblok & { body?: OwnersClubItemStoryblok[] };
}

const Features = ({ ownersClubBlok: blok }: IFeatures) => {
  return (
    <div className={styles.features}>
      {blok?.body?.map((item: OwnersClubItemStoryblok, key: number) =>
        item && item.text ? (
          <div key={key} className={styles.features__featurewrap}>
            <div className={styles.features__feature}>
              {item.icon && item.icon.filename && (
                <Image src={item.icon.filename} alt="" width={34} height={34} />
              )}
              <span>{item.text}</span>
            </div>
          </div>
        ) : null,
      )}
    </div>
  );
};

export default Features;
