export const AmexGrey = () => {
  return (
    <svg
      width="23"
      height="14"
      viewBox="0 0 23 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6486_12078)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.1527 10.8049L18.9909 11.7398H20.5705V12.2556C20.5705 12.7069 20.2159 13.0615 19.7646 13.0615H2.48561C2.03429 13.0615 1.67969 12.7069 1.67969 12.2556V1.77858C1.67969 1.32726 2.03429 0.972656 2.48561 0.972656H19.7646C20.2159 0.972656 20.5705 1.32726 20.5705 1.77858V2.52003H18.604L18.1527 3.77726L17.7014 2.52003H15.1224V4.19634L14.381 2.52003H12.3178L10.1902 7.38779H11.8988V11.7398H15.5738H17.2823L18.1527 10.8049ZM20.5705 11.224H19.2165L18.1205 9.99898L16.9922 11.224H15.6705L17.4757 9.35425L15.6705 7.45227H17.0244L18.1205 8.67727L19.2488 7.45227H20.5705L18.7974 9.32201L20.5705 11.224ZM15.6705 7.45227H12.479V11.224H15.6705L15.6705 10.3536H13.5106V9.77332H15.6382V8.90293H13.5106V8.32267H15.6705L15.6705 7.45227ZM20.5382 6.90421H19.5711V4.26079L18.5718 6.90421H17.6691L16.702 4.26079V6.90421H15.7027L15.7027 6.90423H14.5422L14.2198 6.16278H12.479L12.1567 6.90423H11.0284L12.7047 3.13252H14.0264L15.6705 6.83169V3.1325H17.2823L18.1527 5.48579L18.9909 3.1325L20.5382 3.10026V6.90421ZM12.8336 5.29239L13.3494 4.06739L13.8652 5.29239H12.8336ZM20.5705 10.2569L19.6356 9.22529L20.5705 8.19371V10.2569Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6486_12078">
          <rect
            width="19.3421"
            height="12.25"
            fill="white"
            transform="translate(1.45312 0.875)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const Amex = () => {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6483_72658)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.1843 12.4105L20.1604 13.4992H22V14.0999C22 14.6255 21.587 15.0385 21.0614 15.0385H0.938566C0.412969 15.0385 0 14.6255 0 14.0999V1.89853C0 1.37293 0.412969 0.959961 0.938566 0.959961H21.0614C21.587 0.959961 22 1.37293 22 1.89853V2.76201H19.7099L19.1843 4.22617L18.6587 2.76201H15.6553V4.71423L14.7918 2.76201H12.3891L9.91126 8.43095H11.901V13.4992H16.1809H18.1706L19.1843 12.4105ZM22 12.8986H20.4232L19.1468 11.4719L17.8328 12.8986H16.2935L18.3959 10.7211L16.2935 8.50608H17.8703L19.1468 9.9327L20.4608 8.50608H22L19.9352 10.6836L22 12.8986ZM13.7781 11.8849V11.2091H16.256V10.1955H13.7781V9.51973H16.2935V8.50608H12.5768V12.8986H16.2935V11.8849H13.7781ZM21.9625 7.86782H20.8362V4.78932L19.6724 7.86782H18.6212L17.4949 4.78932V7.86782H16.3311L16.3311 7.86787H14.9795L14.6041 7.00439H12.5768L12.2014 7.86787H10.8874L12.8396 3.47538H14.3789L16.2935 7.78337V3.47532H18.1707L19.1843 6.21594L20.1604 3.47532L21.9625 3.43778V7.86782ZM12.9898 5.99074L13.5905 4.56411L14.1911 5.99074H12.9898ZM22 11.7723L20.9113 10.5709L22 9.36954V11.7723Z"
          fill="#0071CE"
        />
      </g>
      <defs>
        <clipPath id="clip0_6483_72658">
          <rect width="22" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Amex;
