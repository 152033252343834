import { useSelection } from "@/lib/hooks/useSelection";
import { useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "next/navigation";
import { useEffect } from "react";

export const useAuth = () => {
  const { loggedIn, data, isLoading, market } = useSelection();

  const searchParams = useSearchParams();
  const token = data?.token || "";
  const vip = searchParams.has("vip");

  const queryClient = useQueryClient();

  // Handle edge case where user logs in and user data are not updated
  useEffect(() => {
    if (token && !loggedIn) {
      queryClient.invalidateQueries(["selection"]);
    }
  }, [loggedIn, queryClient, token]);

  return {
    token,
    userData: loggedIn,
    isLoggedIn: !!loggedIn,
    isLoading,
    vip: vip,
  };
};
